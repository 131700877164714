<template>
  <li class="list-item">
    <router-link :to="viewPath">
      <div class="list-item-title">
        <span>
          {{ title }}
        </span>
      </div>

      <div>
        <st-date class="format-date">
          {{ created_at }}
        </st-date>
      </div>

      <div class="tag-wrap">
        <st-tag>
          {{ category.name }}
        </st-tag>
        <st-tag :color="is_answer ? 'active' : 'unactive'">{{
          is_answer ? "답변완료" : "답변 대기중"
        }}</st-tag>
      </div>
    </router-link>
  </li>
</template>

<script>
export default {
  name: "ListItem",
  props: {
    id: Number,
    title: String,
    is_answer: Boolean,
    category: Object,
    created_at: String,
  },
  computed: {
    viewPath() {
      return `/view/${this.id}`;
    },
  },
};
</script>

<style lang="scss">
@import "~@/color";

.list-item {
  font-size: 1.6rem;
  padding: 1.8rem 1.6rem;
  border-radius: 6px;
  width: 100%;
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);

  & + .list-item {
    margin-top: 10px;
  }

  &-title {
    margin-bottom: 0.4rem;
    word-break: break-all;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 500;
    text-overflow: ellipsis;
  }

  .tag-wrap {
    display: flex;
    align-items: center;
    margin-top: 1rem;

    & > * + * {
      margin-left: 6px;
    }
  }

  .format-date {
    font-size: 1.3rem;
  }
}
</style>
