<template>
  <div class="container">
    <div class="title">
      <h1>나의 문의</h1>
      <span>서비스 이용에 대한 문의를<br />남겨주세요! 📝</span>
    </div>

    <div class="content">
      <ul class="list" @scroll="onContentScroll">
        <list-item
          v-for="(item, index) in list"
          :key="index"
          :id="item.id"
          :title="item.title"
          :is_answer="item.is_answer"
          :category="item.category"
          :created_at="item.created_at"
        />

        <li v-show="count === 0" class="empty">등록된 문의가 없습니다.</li>

        <li v-show="numOfEnd && count !== 0" class="num-of-end">: )</li>
      </ul>

      <div class="write-button-area">
        <button @click="$router.push('/write')">문의 작성하기</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ListItem from "../components/ListItem";

export default {
  name: "List",
  components: {
    ListItem,
  },
  computed: {
    ...mapState({
      count: "count",
      list: "list",
      loading: "loading",
    }),

    numOfEnd() {
      return this.count === this.list.length;
    },
  },
  methods: {
    onContentScroll(ev) {
      const { offsetHeight, scrollTop } = ev.target;

      if (!this.loading) {
        if (scrollTop + 49 + 200 > offsetHeight) {
          console.log("next");
          this.$store.dispatch("GET_MORE_ASYNC_LIST");
        }
      }
    },
  },
};
</script>

<style lang="scss" scope>
@import "~@/color";

.content {
  .list {
    height: 100%;
    padding: 10px 16px 80px;
    overflow: auto;
  }

  .empty {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 80px);
    font-size: 16px;
    color: $gray05;
  }

  .num-of-end {
    text-align: center;
    margin-top: 20px;
    padding: 24px 0 48px;
    font-size: 16px;
    color: $gray03;
  }
}
</style>
